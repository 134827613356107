import * as React from 'react'
import { MakerUIOptions } from 'maker-ui'
import { theme } from '@oneofnone/design-system'
import Link from 'next/link'

export const AppTheme: MakerUIOptions = {
  ...theme,
  // Wrap all maker-ui links with Next.js Link
  linkFunction: (
    path: string,
    children: React.ReactNode,
    attributes: object
  ) => (
    <Link href={path}>
      <a {...attributes}>{children}</a>
    </Link>
  ),
  useColorDefaults: false,
  breakpoints: ['768px', '960px', '1440px'],
  header: {
    navType: 'basic-center',
    mobileNavType: 'logo-center',
    maxWidth: '100%',
    sticky: true,
    stickyOnMobile: true,
    showWidgetsOnMobile: true,
    showColorButton: false,
    breakpoint: 960,
  },
  mobileMenu: {
    transition: 'fade-down',
    visibleOnDesktop: false,
  },
  content: {
    maxWidth: '100%',
    maxWidthSection: 1200,
  },
  sideNav: {
    collapse: true,
    width: [230],
  },
  footer: {
    maxWidth: '100%',
  },
}
