import React, { useState, useEffect } from 'react'
import {
  Layout as MakerLayout,
  Content,
  Main,
  SideNav,
  ColorButton,
} from 'maker-ui'
import { ErrorPage } from '@oneofnone/react'
import { useRouter } from 'next/router'
import { useAuth, LoginForm, RegisterForm } from '@oneofnone/auth'

import { AppTheme } from './theme'
import { getMenu } from './menu'
import styles from './Layout.module.scss'
import { MoonIcon, SunIcon, ContrastIcon } from '@oneofnone/icons'

interface LayoutProps {
  children: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  const [initialRender, setInitialRender] = useState(true)
  const { asPath } = useRouter()
  const { isLoggedIn, adminAccess, authUser, logout } = useAuth()
  const menu = adminAccess ? getMenu(authUser?.user_metadata.user_role) : []

  useEffect(() => {
    setInitialRender(false)
  }, [])

  return !initialRender ? (
    <MakerLayout options={AppTheme}>
      {adminAccess ? (
        <Content>
          <SideNav
            header={
              <div className="menu-header">
                <h3 className="font-oblique caps">Admin UI</h3>
                <div className="auth-email">{authUser?.email}</div>
              </div>
            }
            footer={
              <div>
                <ColorButton
                  customButton={(theme, attributes) => (
                    <button {...attributes} title={`${theme} mode`}>
                      <div className="flex align-center">
                        {theme === 'light' ? (
                          <>
                            <SunIcon /> Light Mode
                          </>
                        ) : theme === 'dark' ? (
                          <>
                            <MoonIcon /> Dark Mode
                          </>
                        ) : (
                          <>
                            <ContrastIcon /> System Default
                          </>
                        )}
                      </div>
                    </button>
                  )}
                />
                <button
                  onClick={logout}
                  className="btn-logout btn-naked absolute font-oblique caps">
                  Logout
                </button>
              </div>
            }
            pathname={asPath}
            menu={menu}
          />
          <Main id="main">{children}</Main>
        </Content>
      ) : (
        <Content>
          <Main>
            {isLoggedIn && !adminAccess ? (
              <ErrorPage
                title="Unauthorized"
                message="Sorry, you don't have access to this page."
                linkText="Return home"
              />
            ) : (
              <div className={styles.admin_auth}>
                {/* <RegisterForm /> */}
                <LoginForm
                  title={<h2 className="font-oblique caps">Admin Login</h2>}
                  buttonClass="btn-primary"
                />
              </div>
            )}
          </Main>
        </Content>
      )}
    </MakerLayout>
  ) : null
}
