import type { UserRole } from '@oneofnone/types'
import type { MakerMenu } from 'maker-ui'

type MenuItem = {
  label: string
  path: string
  access: UserRole
  className?: string
}

export const menuItems: MenuItem[] = [
  { label: 'Preview', path: '/preview', access: 'CORE' },
  { label: 'Page Refresh', path: '/refresh', access: 'CORE' },
  {
    label: 'Collections',
    path: '/collection-editor',
    access: 'CORE',
  },
  {
    label: 'Products',
    path: '/product-editor',
    access: 'CORE',
    className: 'divider-bottom',
  },
  { label: 'Claims', path: '/claims', access: 'CORE' },
  { label: 'RFID Scans', path: '/rfid-scans', access: 'PRODUCTION' },
  {
    label: 'Vault Requests',
    path: '/vault-requests',
    access: 'PRODUCTION',
  },
  {
    label: 'Analytics',
    path: '/analytics',
    access: 'CORE',
    className: 'divider-top',
  },
  // {
  //   label: 'Payments',
  //   path: '/payments',
  //   access: 'CORE',
  // },
  { label: 'Applications', path: '/applications', access: 'CORE' },
]

export function getMenu(access: UserRole): MakerMenu {
  if (access === 'CORE' || access === 'SUPER_ADMIN') {
    return menuItems
  }

  let menu: MenuItem[] = []
  menuItems.forEach((item) => {
    if (item.access === access) {
      menu.push(item)
    }
  })

  return menu
}
