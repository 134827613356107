import type { AppProps } from 'next/app'
import Head from 'next/head'
import { ToastProvider } from '@maker-ui/notifications'
import { AuthProvider } from '@oneofnone/auth'

import '@oneofnone/auth/dist/index.css'
import '@oneofnone/design-system/dist/index.css'
import '../styles/styles.scss'

import { Layout } from '@/components'

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <link rel="shortcut icon" href="/apple-touch-icon.png" />
        <link key="favicon" rel="shortcut icon" href="/favicon.ico" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
      </Head>
      <ToastProvider>
        <AuthProvider subdomain="admin">
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </AuthProvider>
      </ToastProvider>
    </>
  )
}

export default App
